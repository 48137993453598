import { toastr } from "react-redux-toastr";
import apiClient from "../../gears/apiClient";

export const defaultState = {
  actionText: '',
  description: '',
  id: null,
  notification: '',
  notifyAdmins: false,
  notifyUser: false,
  recordIssue: false,
  severity: '',
  skipText: '',
  title: '',
  type: '',
};

export const onSaveButtonClick = async (
  issueData,
  getOrUpdateIssueDetails,
  setIsLoading,
  id,
  toggleModal,
  setIssueData,
) => {
  const { severity, recordIssue, notifyAdmins, notifyUser, notification, description, actionText, skipText } =
    issueData;
  const dataToSend = {
    severity,
    recordIssue,
    notifyAdmins,
    notifyUser,
    notification,
    description,
    actionText,
    skipText,
  };
  getOrUpdateIssueDetails(
    setIsLoading,
    id,
    toggleModal,
    setIssueData,
    'update',
    dataToSend
  );
};

export const issueDetailsArr = (issueData, onCheckboxClick, onFieldChange, profileData) => [
  {
    id: 'issue-settings-notifyUser',
    label: 'Notify End User',
    name: 'notifyUser',
    value: issueData.notifyUser,
    action: onCheckboxClick,
    inputType: 'checkbox',
  },
  {
    id: 'issue-settings-notifyAdmins',
    label: 'Notify Admin',
    name: 'notifyAdmins',
    value: issueData.notifyAdmins,
    action: onCheckboxClick,
    inputType: 'checkbox',
  },
  {
    id: 'issue-settings-notification',
    label: 'Headline',
    name: 'notification',
    value: issueData.notification,
    action: onFieldChange,
    inputType: 'text',
    max: 40,
  },
  {
    id: 'issue-settings-description',
    label: 'Description',
    name: 'description',
    value: issueData.description,
    action: onFieldChange,
    inputType: 'text',
    max: 120,
  },
  {
    id: 'issue-settings-actionButton',
    label: 'Call to Action Button',
    name: 'actionText',
    value: issueData.actionText,
    action: onFieldChange,
    inputType: 'text',
    max: 10,
  },
  {
    id: 'issue-settings-skipText',
    label: 'Skip Button',
    name: 'skipText',
    value: issueData.skipText,
    action: onFieldChange,
    inputType: 'text',
    max: 10,
    isDisabled: !(
      profileData?.role?.toLowerCase() === 'super admin' || profileData?.role?.toLowerCase() === 'hubble admin'
    ),
  },
];

export const severityOptions = ['minor', 'moderate', 'significant', 'critical'];

export const getOrUpdateIssueDetails = async (
  setIsLoading,
  id,
  toggleModal,
  setIssueData,
  reason = null,
  data = {}
) => {
  setIsLoading(true);
  const request = reason
    ? apiClient.put(`/disruption-config/${id}`, data)
    : apiClient.get(`/disruption-config/${id}`);

  try {
    const response = await request;

    if (reason) {
      toastr.success('Updated Successfuly');
      toggleModal();
    } else {
      setIssueData(response);
    }
  } catch (e) {
    toastr.error(e);
  }
  setIsLoading(false);
};

export const resetToDefault = async (
  id,
  setIsLoading,
  reLoadFunction,
  setIsConfirmModalOpen
) => {
  setIsLoading(true);
  try {
    await apiClient.post(`/disruption-config/${id}/reset`);
    setIsConfirmModalOpen();
    await reLoadFunction();
    toastr.success('Reseted to Default');
  } catch(e) {
    toastr.error(e);
  }
  setIsLoading(false);
}
