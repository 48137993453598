import React from 'react';
import { Input } from "reactstrap";
import { severityOptions } from "./issueSettingsDetailHelper";

const SeveritySelect = ({ value, onChange }) => (
	<Input
		className="form-control w-auto text-capitalize"
		type="select"
		onChange={onChange}
		name="severity-select"
		value={value}
	>
		{severityOptions.map((option) => <option key={option} value={option}>{option}</option>)}
	</Input>
);

export default SeveritySelect;
