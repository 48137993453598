import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import apiClient from '../../gears/apiClient';
import { issuesSettingsFormItems, renderExtensionConfigurationFormItems } from '../../components/forms/formsHelper';
import ComponentLoader from '../../components/ComponentLoader';
import IssueSettingsDetail from '../../components/issueSettingsDetail/IssueSettingsDetail';
import styles from './issuesSettings.module.scss';

const IssuesSettings = () => {
  const [issuesSettingsList, setIssuesSettingsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIssueId, setSelectedIssueId] = useState(null);

  const getOrUpdateIssuesSettings = async (reason = null, dataToSend = []) => {
    setIsLoading(true);
    const request = reason
      ? apiClient.put('/disruption-types', {
          configs: dataToSend,
        })
      : apiClient.get('/disruption-types');
    try {
      const response = await request;
      setIssuesSettingsList(response.issueTypes);
      reason && toastr.success('Updated Successfuly');
    } catch (e) {
      toastr.error(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getOrUpdateIssuesSettings();
  }, []);

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
    setSelectedIssueId(null);
  };

  const onCheckboxClick = (id) => {
    const copyArr = [...issuesSettingsList];
    const currentIssue = copyArr.find((issue) => issue.id === id);
    currentIssue.recordIssue = !currentIssue.recordIssue;
    setIssuesSettingsList(copyArr);
  };

  const onEditButtonClick = (id) => {
    setSelectedIssueId(id);
    setIsModalOpen((prevState) => !prevState);
  };

  const onSaveButtonClick = async () => {
    setIsLoading(true);
    const modifiedArray = issuesSettingsList.map((issue) => ({
      configId: issue.id,
      recordIssue: issue.recordIssue,
    }));

    getOrUpdateIssuesSettings('update', modifiedArray);
  };

  return (
    <div className={`container-fluid content settings ${styles.issuesSettings}`}>
      <Row className="page-title align-items-center">
        <Col sm="6">
          <div className="page-title-box">
            <h2>Issues Settings</h2>
          </div>
        </Col>
      </Row>
      <div className="tab-content page-block page-block_border">
        <div className="settings-tab-block">
          <div className="title">Global settings for the extension users</div>
          <div className="subtitle">
            Select the options below to configure the behavior and features for end users. These settings may take up to
            an hour to go into effect
          </div>
          {isLoading && <ComponentLoader />}
          <div>
            {renderExtensionConfigurationFormItems(
              issuesSettingsFormItems(issuesSettingsList, onCheckboxClick, onEditButtonClick),
            )}
          </div>
          <div className="form-btn d-flex justify-content-between">
            <Button className="button button-default" onClick={onSaveButtonClick} disabled={isLoading}>
              Save changes
            </Button>
          </div>
        </div>
      </div>
      {isModalOpen && <IssueSettingsDetail id={selectedIssueId} isModalOpen={isModalOpen} toggleModal={toggleModal} />}
    </div>
  );
};

IssuesSettings.propTypes = {};

export default IssuesSettings;
