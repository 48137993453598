import React, { useEffect, useRef, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { useSelector } from 'react-redux';
import styles from './issues-modal-mark-complete.module.scss';

function IssuesModalMarkComplete({ openConfirmModal, confirmModalToggle, markCompletedHandler }) {
  const [msg, setMsg] = useState('');
  const textareaRef = useRef(null);
  const { data } = useSelector((state) => state.IssuesAIChat);

  useEffect(() => {
    if (data.length > 0) {
      setMsg(data[1]?.content);
    }
  }, [data]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [msg]);

  const handleChange = (event) => {
    setMsg(event.target.value);
  };
  const submitHandler = (sendEmail) => {
    markCompletedHandler(msg || null, sendEmail);
    confirmModalToggle();
  };
  return (
    <Modal open={openConfirmModal} onClose={confirmModalToggle}>
      <div className={styles.modalWrapper}>
        <div className={styles.header}>
          <h3>Confirm to complete the issue</h3>
          <button className={styles.issueCloseBtn} onClick={confirmModalToggle}>
            <i className="mdi mdi-close" />
          </button>
        </div>
        <FormGroup>
          <Label for="recommendation">Recommendation for the user:</Label>
          <Input
            type="textarea"
            id="recommendation"
            value={msg}
            onChange={handleChange}
            className={styles.textArea}
            innerRef={textareaRef}
          />
        </FormGroup>
        <p>
          Choose one of the two options to mark as completed.: Send recommendations to the user via email or just change
          the status of the issue to completed without sending recommendations via email.
        </p>
        <div className={styles.control}>
          <Button color="success" onClick={() => submitHandler(true)}>
            With Recommendation
          </Button>
          <Button color="success" onClick={() => submitHandler(false)}>
            Without Recommendation
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default IssuesModalMarkComplete;
