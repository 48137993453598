import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

const ExtensionConfigurationFormItem = ({
  id = null,
  label,
  name,
  value,
  action,
  description = null,
  isDisabled = false,
  children,
  setIsConfirmModalOpen,
  inputType = 'checkbox',
  inputMax,
}) => {
  const onInputChage = (e) => {
    const isStoreEmail = e.target?.name === 'store_email' && !e.target.checked;
    if (isStoreEmail) {
      return setIsConfirmModalOpen(true);
    }
    return isDisabled ? null : action(e);
  };

  const isDisabledStyle = isDisabled ? ' disabled' : '';

  return (
    <>
      {inputType === 'checkbox' && (
        <FormGroup className={`form-group_checkbox${isDisabledStyle}`}>
          <Label check>
            {label}
            <Input
              type={inputType}
              name={name}
              checked={value}
              onChange={(e) => onInputChage(typeof id === 'number' ? id : e)}
            />{' '}
            <span />
          </Label>
          {description && (
            <div className="form-group-description">
              {description}
              {children}
            </div>
          )}
        </FormGroup>
      )}
      {inputType === 'text' && (
        <FormGroup>
          <Label check for={id}>
            {label}
          </Label>
          <Input
            id={id}
            type={inputType}
            name={name}
            value={value}
            onChange={(e) => onInputChage(e)}
            maxLength={inputMax}
            disabled={isDisabled}
          />
        </FormGroup>
      )}
    </>
  );
};

ExtensionConfigurationFormItem.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  action: PropTypes.func,
  description: PropTypes.string,
  isDisabled: PropTypes.bool,
  children: PropTypes.node,
  setIsConfirmModalOpen: PropTypes.func,
  inputType: PropTypes.string,
};

export default ExtensionConfigurationFormItem;
