import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import apiClient from '../../gears/apiClient';
import ComponentLoader from '../ComponentLoader';
import { renderExtensionConfigurationFormItems } from '../forms/formsHelper';
import { defaultState, getOrUpdateIssueDetails, issueDetailsArr, onSaveButtonClick, resetToDefault } from './issueSettingsDetailHelper';
import SeveritySelect from './SeveritySelect';
import { useSelector } from 'react-redux';
import styles from './issueSettingsDetail.module.scss';
import ConfirmationModal from '../ConfirmationModal';

const IssueSettingsDetail = ({ id, isModalOpen, toggleModal }) => {
  const { data: profileData } = useSelector((state) => state.Profile);
  const [issueData, setIssueData] = useState(defaultState);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const updateSeverity = (newSeverity) => {
    setIssueData((prevState) => ({ ...prevState, severity: newSeverity }));
  };

  useEffect(() => {
    getOrUpdateIssueDetails(
      setIsLoading,
      id,
      toggleModal,
      setIssueData,
    );
  }, []);

  const onCheckboxClick = (e) => {
    const { name } = e.target;
    setIssueData((prevState) => ({ ...prevState, [name]: !prevState[name] }));
  };

  const onFieldChange = (e) => {
    const { name, value } = e.currentTarget;
    setIssueData((prevState) => ({ ...prevState, [name]: value }));
  };

  const confirmModalToggle = () => {
		setIsConfirmModalOpen(!isConfirmModalOpen);
	}

  const onConfirmButtonClick= async () => {
    await resetToDefault(
      id,
      setIsLoading,
      () => getOrUpdateIssueDetails(
        setIsLoading,
        id,
        toggleModal,
        setIssueData,
      ),
      setIsConfirmModalOpen
    );
  }

  return (
    <>
      <Modal isOpen={isModalOpen} toggle={toggleModal} className={`custom ${styles.issueSettingsDetail}`}>
        <ModalHeader toggle={toggleModal}>Edit Issue: {issueData?.title}</ModalHeader>
        {isLoading && <ComponentLoader />}
        <ModalBody>
          <div className={styles.top}>
          <div className={styles.description}>{issueData.description}</div>
          <Button
            className="button button-default"
            onClick={confirmModalToggle}
            disabled={isLoading}
          >
            Reset to Default
          </Button>
          </div>
          <div className={styles.formGroupNoInput}>
            Severity
            <SeveritySelect value={issueData.severity} onChange={(e) => updateSeverity(e.target.value)} />
          </div>
          <div className="settings">
            {renderExtensionConfigurationFormItems(
              issueDetailsArr(issueData, onCheckboxClick, onFieldChange, profileData),
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="button button-default"
            onClick={() => onSaveButtonClick(
              issueData,
              getOrUpdateIssueDetails,
              setIsLoading,
              id,
              toggleModal,
              setIssueData,
            )}
            disabled={isLoading}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>

      {isConfirmModalOpen && (
        <ConfirmationModal
          isOpen={isConfirmModalOpen}
          confirm={onConfirmButtonClick}
          reject={confirmModalToggle}
          confirmLabel="Confirm"
          rejectLabel="Cancel"
          bodyText="Are you sure to reset values to default?"
        />
      )}
    </>
  );
};

IssueSettingsDetail.propTypes = {
  id: PropTypes.number,
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
};

export default IssueSettingsDetail;
